import i18next from "i18next";
import React from "react";
import { serverUrl } from "../_constants";
import { confService } from "../_services";
import "../css/style.css";
import confLogo from "../img/conf_logo.png";
import offlineLogo from "../img/offline.png";
import onlineLogo from "../img/online.png";

export default function ConfItem(props) {
  const { data, onEdit, onClick, isMobile = false } = props;

  return (
    <li className={isMobile?"conf-list__item-mobile":"conf-list__item"}>
      <div className="card" onClick={props.onEdit}>
        <div className="card__top" onClick={props.onEdit}>
          <img
            src={
              data.image
                ? serverUrl + data.image.replace("public", "/storage")
                : confLogo
            }
            alt=""
            className="object-cover"
          />
          {data.type == "offline" && (
            <span className="card__label"><span>
             <span>{i18next.t("offline_text")}</span>
            </span>
            <img style={{marginLeft:10}} src={offlineLogo} />
            </span>
          )}
          {data.type == "online" && (
            <span className="card__label">
            <span>
            <span>{i18next.t("online_text")}</span>
            </span>
            <img style={{marginLeft:10}} src={onlineLogo} />
            </span>
          )}
          {data.type == "hybrid" && (
            <span className="card__label">
          <span>
          <span>{i18next.t("hybrid_text")}</span>
            </span>
            <img style={{marginLeft:10}} src={onlineLogo} />
            <img style={{marginLeft:10}} src={offlineLogo} />
            </span>
          )}
        
        </div>

        <div className="card__body">
          <p style={{ marginBottom: 10, fontSize: 18, fontWeight: 500 }}>
            {data.name}
          </p>
          <div className="layout__inf">
            <p className="layout__inf-text">
            {confService.getConfDateTimeStr(data.start_time,data.end_time)}
            </p>

            {!data.building_id && (
              <p className="layout__inf-text">{i18next.t("no_adress")}</p>
            )}

            <p className="layout__inf-text">
              {data.hasTicket ? i18next.t('ticket_bought') : i18next.t('no_ticket')}
            </p>
          </div>
          <div className="layout__inf">
            <ul className="layout__inf-list">
              <a onClick={props.onEdit} className="card__link">
                <span>{i18next.t("btn_view")}</span>
              </a>
            </ul>
          </div>
        </div>
      </div>
    </li>
  );
}
