import React from "react";
import "../css/style.css";
import { connect } from "react-redux";
import { history, store } from "../_helpers";
import ConfItem from "./ConfItem";
import ConfPlaceholder from "./ConfPlaceholder";
import { userService } from "../_services";

import { userActions, appActions } from "../_actions";
import i18next from "i18next";

class ConfListPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isLoading: false, start_date: null, end_date: null };
    store.subscribe(this.storeChange);
  }

  componentDidMount() {
    this.setState({ lang: this.props.app.lang });
    let self = this;

    let domenStr = window.location.href
      .replace("https://", "")
      .replace("http://", "");
    let confDomen = domenStr.split("/")[0];

    if (
      confDomen != "demo.naviconf.com/" &&
      confDomen != "lk.naviconf.com/" &&
      confDomen != "demo.naviconf.com" &&
      confDomen != "lk.naviconf.com"
    ) {
      const conf_id = localStorage.getItem("domen_conf_id");

      if (conf_id > 0) {
        history.push("/event/" + conf_id);
      } else {
        userService.getWrapper(
          "api/conference-domain?domain=" + confDomen,
          (data) => {
            if (!data.data.conference_id)
              this.props.dispatch(userActions.conf_list());
            else {
              localStorage.setItem("navic_redirect", data.data.conference_id);
              history.push("/event/" + data.data.conference_id);
            }
          },
          (error) => {
            self.props.dispatch(userActions.set_error(error));
          }
        );
      }
    } else this.props.dispatch(userActions.conf_list());
  }

  componentWillReceiveProps(nextProps) {
    //this.setState({ lang: this.props.app.lang });
    if (nextProps.app.lang !== this.state.lang) {
      this.setState({ lang: nextProps.app.lang });
      this.props.dispatch(userActions.conf_list());
    }
  }

  storeChange = () => {};

  onItemClick = () => {};

  onItemEdit = (e) => {
    this.props.dispatch(userActions.set_conf(e));
    this.props.dispatch(appActions.setpage("conf_param", e.id));
  };

  onItemSchedule = (e) => {
    this.props.dispatch(userActions.set_conf(e));
    this.props.dispatch(appActions.setpage("conf_prog", e.id));
  };

  onItemDelete = (id) => {
    const self = this;
    const res_dialog = window.confirm(i18next.t("del_event"));
    if (res_dialog === true) {
      userService.delWrapper(
        "api/conference/" + id,
        (data) => {
          self.props.dispatch(userActions.conf_list());
        },
        (error) => {
          self.props.dispatch(userActions.set_error(error));
        }
      );
    }
  };

  changeDate = (index, event) => {
    if (index === 0) this.setState({ start_date: event.target.value });
    else this.setState({ end_date: event.target.value });
  };

  addNewConf = () => {
    this.props.dispatch(userActions.set_conf(null));
    this.props.dispatch(appActions.setpage("new_conf"));
  };

  resetDateFilter = () => {
    this.setState({ end_date: "", start_date: "" });
    this.forceUpdate();
  };

  render() {
    const self = this;
    const { start_date, end_date } = this.state;
    const { user } = this.props;

    return (
      <div className="layout">
        <div className="layout__contains">
          <div style={{ display: "flex", flexDirection: "row" }}>
            <h1 className="layout__title">{i18next.t("confs_title")}</h1>

            <div
              className="date-wrap form__group-row"
              style={{ marginLeft: 40 }}
            >
              <label htmlFor="start">{i18next.t("date_from")}</label>
              <input
                type="date"
                className="form__input"
                id="start"
                value={start_date}
                name="trip-start"
                style={{ minWidth: 180 }}
                onChange={(e) => this.changeDate(0, e)}
              />
            </div>

            <div className="date-wrap form__group-row">
              <label htmlFor="start"> {i18next.t("date_to")}</label>
              <input
                type="date"
                className="form__input"
                id="start"
                value={end_date}
                name="trip-start"
                onChange={(e) => this.changeDate(1, e)}
                style={{ minWidth: 180 }}
              />

              <button
                style={{ marginLeft: 20, minWidth: 200 }}
                className="btn"
                onClick={this.resetDateFilter}
              >
                {i18next.t("reset_dates")}
              </button>
            </div>
          </div>

          <div className="layout__btn">
            <button className="btn" onClick={self.addNewConf}>
              {i18next.t("create_event")}
            </button>
          </div>
        </div>

        <ul className="conf-list">
          {user.conflist.length > 0 &&
            user.conflist
              //.filter((e) => e.contracts_for_speakers === 0)
              .filter(
                (e) => !start_date || e.start_time.substr(0, 10) > start_date
              )
              .filter((e) => !end_date || e.end_time.substr(0, 10) < end_date)
              .map((item, index) => (
                <ConfItem
                  data={item}
                  key={index}
                  onClick={() => self.onItemClick(item.id)}
                  onEdit={() => self.onItemEdit(item)}
                  onSchedule={() => self.onItemSchedule(item)}
                  onDelete={() => self.onItemDelete(item.id)}
                />
              ))}
          <ConfPlaceholder onClick={self.addNewConf} />
        </ul>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { app, user, authentication } = state;

  return {
    user,
    app,
    authentication,
  };
}

const connectedConfListPage = connect(mapStateToProps)(ConfListPage);
export { connectedConfListPage as ConfListPage };
