import React from "react";
import { connect } from "react-redux";
import { history, store } from "../_helpers";
import "../css/grid.css";
import "../css/style.css";

import Button from "@material-ui/core/Button";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";

import i18next from "i18next";
import { userActions } from "../_actions";
import ContactInfo from "./ContactInfo";
import QRDialog from "./QRDialog";
import SlotInfo from "./SlotInfo";
import SectionInfo from "./SectionInfo";
import SpeakerInfo from "./SpeakerInfo";
import SponsorInfo from "./SponsorInfo";
import TicketDialog from "./TicketDialog";
import UserInfoDialog from "./UserInfoDialog";

import { ConferenceNotSelected } from "../_components/ConferenceNotSelected";
import StarActive from "../_components/StarActive";
import StarNotActive from "../_components/StarNotActive";
import { confService, userService } from "../_services";

import { serverUrl } from "../_constants";
import confLogo from "../img/conf_logo.png";
import inetLogo from "../img/inet.png";
import offlineLogo from "../img/offline.png";
import onlineLogo from "../img/online.png";
import personLogo from "../img/person.png";

import ruFlag from "../img/ru.png";
import enFlag from "../img/en.png";
import cnFlag from "../img/cn.png";
import { duration } from "@material-ui/core";

const DECLINED = "declined";

function replaceName(name) {
  if (!name) return name;

  return name;
}

function getStorage(name) {
  return localStorage.getItem(name);
}

const NO_SECTION = "";

const getDate = (time) => {
  if (!time) return i18next.t("to_be_specified");

  const time1 = time.substr(0, 10);

  return time1.split("-").reverse().join("-");
};

const AddSectDescAndSort = (srcSect, sectDescs) => {
  let tmpSect = [...srcSect];

  console.log(sectDescs, srcSect);

  for (let descItem of sectDescs) {
    let sectInd = tmpSect.findIndex((item) => item.id == descItem.id);

    if (sectInd >= 0) {
      console.log(sectInd, tmpSect[sectInd], descItem);
      tmpSect[sectInd].description = { ...descItem.description };
    }
  }

  //tmpSect = tmpSect.sort((a, b) => a.order - b.order);
  console.log(tmpSect);

  return tmpSect;
};

class ClientConfPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isPublic: false,
      isErr: "",
      isLoading: false,
      addr: "",
      sponsors: [],
      scheduleData: [],
      schFav: [],
      hasTicket: false,
      oneDaySchedule: [],
      curDateIndex: 0,
      table_class: "col-md-4",
      tickets: [],
      openTicket: false,
      slotInfoOpen: false,
      sectionInfoOpen: false,
      speakerInfoOpen: false,
      sponsorInfoOpen: false,
      slotData: {},
      sectionData: {},
      speakerData: {},
      sponsorData: {},
      favChedule: [],
      blds: [],
      id: 0,
      groupBySections: true,
      lessons: [],
      urlt: "",
      translation: null,
      showInfoDialog: false,
      showQRDialog: false,
      needFields: [],
      optionalFields: [],
      showContactsSetting: "hide",
      oneColumn: true,
      speakers: [],
      isMobile: false,
      speakersOpen: false,
      openQR: false,
      ticketData: null,
      ticketsInfo: [],
      qrData: "",
      editSpeakerOrder: false,
      editSponsorOrder: false,
      questionable: 0,
    };
    store.subscribe(this.storeChange);
  }

  componentDidMount() {
    let lang = getStorage("navic_lang") ?? "ru";

    i18next.init({
      lng: lang,
      resources: require(`../_lang/${lang}.json`),
    });

    let isNoMenu = window.location.href.indexOf("/event/") >= 0 ? true : false;

    //localStorage.setItem("tickets_opened", "1");

    this.setState({ lang: lang, isNoMenu });
    this.setState({ isMobile: window.screen.width < 600 });
    this.getAllData();
  }

  changeLang = () => {
    let { lang } = this.state;
    if (lang == "ru") lang = "en";
    else if (lang == "en") lang = "cn";
    else lang = "ru";

    localStorage.setItem("navic_lang", lang);
    this.setState({ lang });

    i18next.init({
      lng: lang,
      resources: require(`../_lang/${lang}.json`),
    });
  };

  getAllData = () => {
    //if (window.location.href.indexOf("_view") < 0) return;

    let token = getStorage("token");

    if (window.location.href.indexOf("event") < 0) {
      let urls = window.location.href.split("/");

      let conf_id = urls[urls.length - 1];

      if (conf_id>0)
      {

      this.getConfInfo(conf_id);
      this.getSchedule(conf_id);
      //scheduleService.getSpeakerList(conf_id);

      this.setState({ id: conf_id, isPublic: token ? false : true });
      }
    } else {
      let conf_id = 0;

      let urls = window.location.href.split("/");

      conf_id = urls[urls.length - 1];

      if (conf_id >0) {
        this.setState({ id: conf_id, isPublic: token ? false : true });
        this.getSchedule(conf_id);
        //scheduleService.getSpeakerList(conf_id);
        this.getConfInfo(conf_id, true);

        // userService.getAsync('api/conference/'+selectedConf.id+'/students');
      }
    }
  };

  componentWillReceiveProps(nextProps) {
    //this.setState({ lang: this.props.app.lang });
    if (nextProps.app.lang !== this.state.lang) {
      this.setState({ lang: nextProps.app.lang });
      this.getAllData();
    }
  }

  isNavigateEnabled = () => {
    const {
      navigation_building_description,
      navigation_building_image,
      map_description,
      map_image,
      map_link,
      navigation_description,
      navigation_image,
    } = this.state;

    return (
      navigation_building_description ||
      navigation_building_image ||
      map_description ||
      map_link ||
      map_image ||
      navigation_description ||
      navigation_image
    );
  };

  openSpeakerFromSlot = (speaker) => {
    this.setState({
      speakerData: { ...speaker },
      speakerInfoOpen: true,
    });
  };

  downloadPdf = () => {
    const { classes, dispatch } = this.props;
    const { id } = this.state;

    var windowReference = window.open();

    userService.getWrapper(
      "api/schedule/" + id + "/export/html?pdf=1",
      (data) => {
        //window.open(serverUrl + data.data, "_blank");
        //window.location.assign(serverUrl+ data.data);
        windowReference.location = serverUrl + data.data;
      },
      (error) => {
        dispatch(userActions.set_error(error));
      }
    );
  };

  getConfInfo = (id, isPublic = false) => {
    const self = this;

    let tickets_opened = localStorage.getItem("tickets_opened");

    if (localStorage.getItem("token")) {
      this.getTicketByConf(id);
    }

    confService.confInfo(
      id,
      (data) => {
        if (data.status == 200) {
          self.setState({
            isErr: false,
            name: data.data.name,
            format: data.data.type,
            contacts: data.data.contacts,
            comment: data.data.description,
            streamUrl: data.data.navigation_description,
            image: data.data.image
              ? data.data.image.replace("public", "storage")
              : null,
            building_id: data.data.building_id,
            start_time: data.data.start_time,
            end_time: data.data.end_time,
            selectedBldId: data.data.building_id,
            map_description: data.data.map_description,
            map_image: data.data.map_image,
            map_link: data.data.map_link,
            addr: data.data.building
              ? data.data.building.name + ", " + data.data.building.address
              : i18next.t("undefined"),
            navigation_building_description:
              data.data.navigation_building_description,
            navigation_building_image: data.data.navigation_building_image,
            navigation_description: data.data.navigation_description,
            navigation_image: data.data.navigation_image,
            tickets: data.data.ticket_types,
            sponsors: data.data.sponsors,
            wifi: data.data.wifi,
            hasTicket: data.data.hasTicket || self.props.isOrg,
            questionable: data.data.questionable,
            urlt: data.data.url,
            translation: data.data.translation,
          });

          let is_free = true;
          for (let i = 0; i < data.data.ticket_types.length; i++)
            if (data.data.ticket_types[i].cost) {
              is_free = false;
              break;
            }

          if (
            tickets_opened == "1" &&
            !data.data.hasTicket &&
            !self.props.isOrg
          ) {
            if (data.data.ticket_types.length === 1) {
              localStorage.setItem("tickets_opened", "");

              let tid = data.data.ticket_types[0].id;
              self.closeTicketDialog(tid);
            } else {
              setTimeout(() => {
                self.setState({ openTicket: true });
              }, 1000);
            }
          }

          localStorage.setItem("tickets_opened", "");

          if (is_free || isPublic)
            self.setState({ ticket_text: i18next.t("tiket_get") });
          else {
            let ticket_price = "";
            if (data.data.ticket_types.length === 1) {
              ticket_price = " (" + data.data.ticket_types[0].cost + " Р)";
            }

            self.setState({
              ticket_text: i18next.t("ticket_buy") + ticket_price,
            });
          }
        }
      },
      (error) => {
        console.log(error);

        self.setState({ isErr: true, isLoading: false });
        if (error != "") {
          //self.setState({ error, isLoading: false });
        }
      }
    );
  };

  getTicketQR = (id) => {
    const { classes, dispatch } = this.props;

    userService.getWrapper(
      "api/ticket/" + id,
      (data) => {
        console.log(data);
        if (data.data.img) {
          this.setState({ qrData: data.data.img, openQR: true });
        }
      },
      (error) => {
        dispatch(userActions.set_error(error));
      }
    );
  };

  getTicketByConf = (id) => {
    const { classes, dispatch } = this.props;

    userService.getWrapper(
      "api/ticket?conference_id=" + id,
      (data) => {
        this.setState({ ticketsInfo: Object.values(data.data) });
      },
      (error) => {
        dispatch(userActions.set_error(error));
      }
    );
  };

  sendQ = (text) => {
    const { slotData } = this.state;
    let self = this;

    if (slotData && slotData.id) {
      let post_data = { lesson_id: slotData.id, description: text };
      userService.postWrapper(
        "api/question",
        post_data,
        function (data) {
          console.log(data);
          alert(i18next.t("quest_sent"));
        },
        function (error) {
          if (error != "") {
            self.setState({ error: error });
          }
        }
      );
    }
  };

  changeSectOpen = (e, roomInd, sectInd, isBySection) => {
    e.stopPropagation();
    const { oneDaySchedule } = this.state;

    if (!isBySection) {
      oneDaySchedule.rooms[roomInd].sections[sectInd].isOpen =
        !oneDaySchedule.rooms[roomInd].sections[sectInd].isOpen;
    } else {
      oneDaySchedule.sections[sectInd].isOpen =
        !oneDaySchedule.sections[sectInd].isOpen;
    }

    this.setState({ oneDaySchedule });
  };

  redirectToConf = () => {
    const { id } = this.state;

    let token = getStorage("token");

    if (token) {
      history.push("/conf_view/" + id);
    } else {
      localStorage.setItem("navic_redirect", "/conf_view/" + id);
      localStorage.setItem("tickets_opened", "1");
      history.push("/login");
    }
  };

  closeTicketDialog = (tid) => {
    this.setState({ openTicket: false });

    const { user } = this.props;
    const { isPublic, id } = this.state;
    const self = this;

    if (tid > 0) {
      if (isPublic) {
        self.redirectToConf();
      } else if (
        (user.info.type && user.info.type != "org") ||
        !user.info.type
      ) {
        userService.getWrapper(
          "api/buy-ticket/" + tid,
          (data) => {
            const need_fld = [...data.data.need_fill_anketa];
            const optional_fld = [...data.data.optional_anketa];
            let redirectUrl = data.data.redirect;
            if (need_fld.length > 0 || optional_fld.length > 0) {
              self.setState({
                showInfoDialog: true,
                needFields: need_fld,
                optionalFields: optional_fld,
                showContactsSetting: data.data.show_contacts,
                ticketRedirect: redirectUrl,
              });
            } else {
              //const url = `${serverUrl}buy-ticket/${user.info.id}/${tid}`;
              window.location.href = redirectUrl;
            }
          },
          (error) => {
            self.props.dispatch(userActions.set_error(error));

            alert(error);
          }
        );
      } else {
        alert(i18next.t("cant_buy_ticket_from_org"));
      }
    }
  };

  setSchedule = (data, groupType) => {
    const months = {
      "01": i18next.t("m01"),
      "02": i18next.t("m02"),
      "03": i18next.t("m03"),
      "04": i18next.t("m04"),
      "05": i18next.t("m05"),
      "06": i18next.t("m06"),
      "07": i18next.t("m07"),
      "08": i18next.t("m08"),
      "09": i18next.t("m09"),
      10: i18next.t("m10"),
      11: i18next.t("m11"),
      12: i18next.t("m12"),
    };

    console.log(data);

    const { groupBySections, isPublic, id } = this.state;

    let speakers_dict = {};

    if (groupType == undefined) groupType = groupBySections;

    const schedule = [];
    const dates = [];
    const rooms = [];
    const favChedule = [];
    let sectionIds = [];

    let t_class = "col-md-12";

    if (!isPublic)
      for (let i = 0; i < data.length; i++) {
        if (
          data[i].is_favorite == true ||
          (data[i].entity && data[i].entity.is_favorite)
        ) {
          let objTmp = {};

          if (data[i].entity) {
            objTmp = {
              ...data[i],
              name: data[i].entity.name,
              description: data[i].entity.description
                ? data[i].entity.description
                : data[i].description,
              type: data[i].entity.type,
              is_favorite: data[i].entity.is_favorite,
              id: data[i].entity.id,
              room_number: data[i].room_number,
              url_online: data[i].url_online,
              available_seats: data[i].entity.available_seats,
              tags: data[i].entity.tags ?? [],
              translation: {...data[i].entity.translation} ?? null,
            };
          } else {
            objTmp = { ...data[i] };
          }

          let sp_str = "";
          let speakers = data[i].entity
            ? data[i].entity.speakers.filter((item) => item.status != DECLINED)
            : data[i].speakers.filter((item) => item.status != DECLINED);

          for (let m = 0; m < speakers.length; m++) {
            sp_str += speakers[m].name;
            if (m < speakers.length - 1) sp_str += ", ";
          }

          objTmp.sp_str = sp_str;
          objTmp.speaers = [...speakers];

          let timeStr = "";
          let tmpTime = [];
          if (objTmp.start_time) {
            tmpTime = objTmp.start_time.split(":");
            if (tmpTime.length > 1) {
              timeStr = `${tmpTime[0]}:${tmpTime[1]}`;

              objTmp.ftime = timeStr;
              objTmp.time = timeStr;
            }

            if (objTmp.duration) {
              let newTime = new Date(
                objTmp.date + "T" + objTmp.start_time + ".0000Z"
              );
              let endTime = new Date(
                newTime.getTime() +
                  objTmp.duration * 60 * 1000 +
                  newTime.getTimezoneOffset() * 60 * 1000
              );
              let hrs = endTime.getHours();
              if (hrs < 10) hrs = "0" + hrs;
              let mnt = endTime.getMinutes();
              if (mnt < 10) mnt = "0" + mnt;
              objTmp.endtime = hrs + ":" + mnt;
            }
          }

          if (objTmp.date) {
            timeStr = "";
            let date0 = objTmp.date.substr(0, 10);
            tmpTime = date0.split("-");
            if (tmpTime.length > 2) {
              timeStr = `${tmpTime[2]} ${months[tmpTime[1]]}`;
              objTmp.fdate = timeStr;
            }
          }

          favChedule.push({ ...objTmp });
        }
      }

    for (let i = 0; i < data.length; i++) {
      if (data[i].date) {
        let oldDate = new Date(data[i].date);
        let speakers = [];

        if (data[i].entity && data[i].entity.speakers)
          speakers = data[i].entity.speakers.filter(
            (item) => item.status != DECLINED
          );

        if (data[i].speakers)
          speakers = data[i].speakers.filter((item) => item.status != DECLINED);

        console.log(speakers);

        for (let m = 0; m < speakers.length; m++) {
          if (!speakers_dict[speakers[m].id])
            speakers_dict[speakers[m].id] = { ...speakers[m] };
        }

        let dayData = "" + oldDate.getDate();
        if (dayData.length < 2) dayData = "0" + dayData;

        let mData = "" + (1 + oldDate.getMonth());
        if (mData.length < 2) mData = "0" + mData;

        let cdate = `${oldDate.getFullYear()}-${mData}-${dayData}`;

        let ctime = data[i].start_time;
        let time = "";

        if (ctime) {
          ctime = ctime.split(":");

          if (ctime.length > 0) time = `${ctime[0]}:${ctime[1]}`;
        }

        let date_array = cdate.substr(0, 10).split("-");

        let title = `${date_array[2]} ${months[date_array[1]]}`;

        let dateIndex = -1;

        for (let j = 0; j < dates.length; j++)
          if (dates[j].date == cdate) {
            dateIndex = j;
          }

        if (dateIndex < 0) {
          if (groupType == false)
            dates.push({ date: cdate, title: title, rooms: [] });
          else dates.push({ date: cdate, title: title, sections: [] });
          dateIndex = dates.length - 1;
        }

        console.log(dates, groupType);

        // смотрим как группировать дальше

        if (groupType == false) {
          let roomIndex = -1;

          for (let l = 0; l < dates[dateIndex].rooms.length; l++)
            if (dates[dateIndex].rooms[l].room_number == data[i].room_number)
              roomIndex = l;

          if (roomIndex < 0) {
            dates[dateIndex].rooms.push({
              room_id: data[i].room_id,
              room_number: data[i].room_number,
              sections: [],
            });
            roomIndex = dates[dateIndex].rooms.length - 1;
          }

          let sectIndex = -1;

          for (
            let k = 0;
            k < dates[dateIndex].rooms[roomIndex].sections.length;
            k++
          )
            if (data[i].section) {
              if (
                dates[dateIndex].rooms[roomIndex].sections[k].id ==
                data[i].section.id
              )
                sectIndex = k;
            } else {
              if (dates[dateIndex].rooms[roomIndex].sections[k].id == 0)
                sectIndex = k;
            }

          if (sectIndex < 0) {
            if (data[i].section) {
              let mod_str = "";
              let moders = [...data[i].section.speakers];

              for (let m = 0; m < moders.length; m++) {
                mod_str += moders[m].name;
                if (m < moders.length - 1) mod_str += ", ";
              }

              dates[dateIndex].rooms[roomIndex].sections.push({
                id: data[i].section.id,
                name: data[i].section.name,
                slots: [],
                moderators: mod_str,
                order_id: data[i].section.order_id,
                description: data[i].section.description ?? {},
                isOpen: true,
              });
              sectionIds.push(data[i].section.id);
            } else
              dates[dateIndex].rooms[roomIndex].sections.push({
                id: 0,
                name: NO_SECTION,
                slots: [],
                description: {},
                isOpen: true,
                order_id: 9999,
              });
            sectIndex = dates[dateIndex].rooms[roomIndex].sections.length - 1;
          }

          let sp_str = "";

          for (let m = 0; m < speakers.length; m++) {
            sp_str += speakers[m].name;
            if (m < speakers.length - 1) sp_str += ", ";
          }

          let theme_obj = {};

          if (data[i].entity) {
            theme_obj = {
              name: data[i].entity.name,
              type: data[i].entity.type,
              is_favorite: data[i].entity.is_favorite,
              description: data[i].entity.description,
              id: data[i].entity.id,
              slot_type: data[i].slot_type,
              room_number: data[i].room_number,
              url_online: data[i].url_online,
              available_seats: data[i].entity.available_seats,
              tags: data[i].entity.tags ?? [],
              translation: {...data[i].entity.translation} ?? null,
              
            };
          } else {
            theme_obj = { ...data[i] };
          }

          let tmpObj = {
            ...theme_obj,
            time: time,
            sp_str: sp_str,
            speakers: [...speakers],
          };

          if (data[i].start_time && data[i].duration) {
            let newTime = new Date(
              data[i].date + "T" + data[i].start_time + ".0000Z"
            );
            let endTime = new Date(
              newTime.getTime() +
                data[i].duration * 60 * 1000 +
                newTime.getTimezoneOffset() * 60 * 1000
            );
            let hrs = endTime.getHours();
            if (hrs < 10) hrs = "0" + hrs;
            let mnt = endTime.getMinutes();
            if (mnt < 10) mnt = "0" + mnt;
            tmpObj.endtime = hrs + ":" + mnt;
          }

          dates[dateIndex].rooms[roomIndex].sections[sectIndex].slots.push(
            tmpObj
          );

          if (dates[0].rooms.length == 2) t_class = "col-md-6";
          if (dates[0].rooms.length > 2) t_class = "col-md-4";
          if (dates[0].rooms.length > 3) t_class = "col-md-3";
          if (dates[0].rooms.length > 4) t_class = "col-md-2";
          //if (dates[0].rooms.length > 6) t_class = 'col-md-1';
        } else {
          let sectIndex = -1;

          console.log("DD", dateIndex, dates[dateIndex]);

          for (let k = 0; k < dates[dateIndex].sections.length; k++)
            if (data[i].section) {
              if (dates[dateIndex].sections[k].id == data[i].section.id)
                sectIndex = k;
            } else {
              if (dates[dateIndex].sections[k].id == 0) sectIndex = k;
            }

          if (sectIndex < 0) {
            console.log(data[i]);

            if (data[i].section) {
              let mod_str = "";
              let moders = [...data[i].section.speakers];

              for (let m = 0; m < moders.length; m++) {
                mod_str += moders[m].name;
                if (m < moders.length - 1) mod_str += ", ";
              }

              console.log(data[i].section);

              dates[dateIndex].sections.push({
                id: data[i].section.id,
                name: data[i].section.name,
                slots: [],
                moderators: mod_str,
                order_id: data[i].section.order_id,
                description: data[i].section.description ?? {},
                isOpen: true,
              });

              sectionIds.push(data[i].section.id);
            } else
              dates[dateIndex].sections.push({
                id: 0,
                name: NO_SECTION,
                slots: [],
                description: {},
                order_id: 9999,
                isOpen: true,
              });

            sectIndex = dates[dateIndex].sections.length - 1;
          }

          let sp_str = "";

          for (let m = 0; m < speakers.length; m++) {
            sp_str += speakers[m].name;
            if (m < speakers.length - 1) sp_str += ", ";
          }

          let theme_obj = {};

          if (data[i].entity) {
            theme_obj = {
              name: data[i].entity.name,
              type: data[i].entity.type,
              description: data[i].entity.description,
              is_favorite: data[i].entity.is_favorite,
              id: data[i].entity.id,
              slot_type: data[i].slot_type,
              room_number: data[i].room_number,
              url_online: data[i].url_online,
              available_seats: data[i].entity.available_seats,
              tags: data[i].entity.tags ?? [],
              translation: {...data[i].entity.translation} ?? null,
            };
          } else {
            theme_obj = { ...data[i] };
          }

          let tmpObj = {
            ...theme_obj,
            time: time,
            sp_str: sp_str,
            speakers: [...speakers],
          };

          console.log("TMP", data[i]);

          if (data[i].start_time && data[i].duration) {
            let newTime = new Date(
              data[i].date + "T" + data[i].start_time + ".0000Z"
            );
            let endTime = new Date(
              newTime.getTime() +
                data[i].duration * 60 * 1000 +
                newTime.getTimezoneOffset() * 60 * 1000
            );
            let hrs = endTime.getHours();
            if (hrs < 10) hrs = "0" + hrs;
            let mnt = endTime.getMinutes();
            if (mnt < 10) mnt = "0" + mnt;
            tmpObj.endtime = hrs + ":" + mnt;
          }

          dates[dateIndex].sections[sectIndex].slots.push(tmpObj);

          if (dates[0].sections.length == 2) t_class = "col-md-6";
          if (dates[0].sections.length > 2) t_class = "col-md-4";
          if (dates[0].sections.length > 3) t_class = "col-md-3";
          if (dates[0].sections.length > 4) t_class = "col-md-2";
          //if (dates[0].sections.length > 6) t_class = 'col-md-1';
        }
      } else {
        console.log("ERR", i);
      }
    }

    this.setState({ speakers: Object.values(speakers_dict) });

    this.setState({
      scheduleData: dates,
      favChedule,
      table_class: t_class,
      oneDaySchedule:
        this.state.curDateIndex >= 0
          ? { ...dates[this.state.curDateIndex] }
          : { rooms: [], sections: [] },
    });

    this.clickDate(this.state.curDateIndex, groupType, [...dates]);

    let urls = [];


    for (let i = 0; i < sectionIds.length; i++)
      urls.push(`api/section/${sectionIds[i]}?conference_id=${id}`);

    let self = this;

    const { curDateIndex } = this.state;

    const scheduleData = [...dates];
    const oneDaySchedule =
      this.state.curDateIndex >= 0
        ? { ...dates[this.state.curDateIndex] }
        : { rooms: [], sections: [] };

        
    /*
    userService.getAsync(
      urls,
      function (sectData) {
        const sectionsDesc = sectData.map(function (item) {
          let tmpDesc = { description: "", room: "", time: "", url: "" };

          if (item.data.description) {
            try {
              tmpDesc = JSON.parse(item.data.description);
            } catch (e) {}
          }

          return { id: item.data.id, description: tmpDesc };
        });
        //for (let i = 0; i < sectData.length; i++) {}
        console.log(curDateIndex);
        console.log(scheduleData);

        if (groupType == true) {
          for (let j = 0; j < scheduleData.length; j++) {
            console.log("SS", scheduleData[j].sections);
            if (scheduleData[j].sections) {
              scheduleData[j].sections = [
                ...AddSectDescAndSort(scheduleData[j].sections, sectionsDesc),
              ];
            }
          }

          oneDaySchedule.sections = [
            ...AddSectDescAndSort(oneDaySchedule.sections, sectionsDesc),
          ];
          self.setState({ scheduleData, oneDaySchedule });
        } else {
          for (let i = 0; i < scheduleData.length; i++) {
            if (scheduleData[i].rooms && scheduleData[i].rooms.length > 0)
              for (let j = 0; j < scheduleData[i].rooms.length; j++) {
                scheduleData[i].rooms[j].sections = [
                  ...AddSectDescAndSort(
                    scheduleData[i].rooms[j].sections,
                    sectionsDesc
                  ),
                ];
              }
          }

          console.log("ODS", oneDaySchedule);
          for (let j = 0; j < oneDaySchedule.rooms.length; j++) {
            oneDaySchedule.rooms[j].sections = [
              ...AddSectDescAndSort(
                oneDaySchedule.rooms[j].sections,
                sectionsDesc
              ),
            ];
          }

          self.setState({ scheduleData, oneDaySchedule });

          //console.log(oneDaySchedule, scheduleData);
        }
      },
      function (err) {
        store.dispatch(userActions.set_error(err));
      }
    );
    */


  };

  getSchedule = (id) => {
    let self = this;

    userService.getWrapper(
      `api/schedule/${id}`,
      (data) => {
        if (data.status == 200) {
          self.setSchedule([...data.data.lessons]);
          self.setState({ lessons: [...data.data.lessons] });
        }
      },
      (error) => {
        if (error != "") {
          self.setState({ error, isLoading: false });
        }
      }
    );
  };

  clickToList = () => {
    history.push("/events");
  };

  clickGroup = () => {
    const { lessons, groupBySections, curDateIndex, scheduleData } = this.state;

    const newGroup = !groupBySections;

    this.setState({ groupBySections: newGroup });
    this.setSchedule(lessons, newGroup);
  };

  storeChange = (e) => {};

  clickDate = (ind, groupType, schedule) => {
    if (ind >= 0) {
      let t_class = "col-md-12";
      let col_count = 1;

      if (groupType) {
        if (schedule[ind] && schedule[ind].sections)
          col_count = schedule[ind].sections.length;
      } else if (schedule[ind] && schedule[ind].rooms)
        col_count = schedule[ind].rooms.length;
      if (col_count == 2) t_class = "col-md-6";
      if (col_count > 2) t_class = "col-md-4";
      if (col_count > 3) t_class = "col-md-3";
      if (col_count > 4) t_class = "col-md-2";
      //if (col_count > 6) t_class = 'col-md-1';

      this.setState({
        oneDaySchedule: { ...schedule[ind] },
        curDateIndex: ind,
        table_class: t_class,
      });
    } else
      this.setState({
        oneDaySchedule: { rooms: [] },
        curDateIndex: ind,
        table_class: "col-md-12",
      });
  };

  delImage = (tp) => {
    this.uploadFile(null, tp);
  };

  clickTicketMenu = () => {
    const { isPublic, id, hasTicket, ticketsInfo, tickets } = this.state;

    if (hasTicket) {
      console.log(ticketsInfo);
      if (ticketsInfo[0] && ticketsInfo[0].id)
        this.getTicketQR(ticketsInfo[0].id);
    } else if (isPublic) {
      let token = getStorage("token");
      console.log("TOKEN", token);
      if (token) {
        history.push("/conf_view/" + id);
      } else {
        localStorage.setItem("navic_redirect", "/conf_view/" + id);
        history.push("/login");
      }
    } else {
      if (tickets.length === 1) {
        let tid = tickets[0].id;
        this.closeTicketDialog(tid);
      } else {
        this.setState({ openTicket: true });
      }
    }
  };

  onSave = (tp) => {};

  onTextChange = (e, tp) => {};

  clickStar = (e, item, val) => {
    e.stopPropagation();
    const self = this;

    const url1 = "api/lesson/" + item.id + "/favorite";

    if (val == 1) {
      userService.postWrapper(
        url1,
        {},
        function (data) {
          self.getSchedule(self.state.id);
        },
        function (error) {
          if (error != "") {
            self.setState({ error: error, loadingTickets: false });
          }
        }
      );
    } else {
      userService.delWrapper(
        url1,
        function (data) {
          self.getSchedule(self.state.id);
        },
        function (error) {
          if (error != "") {
            self.setState({ error: error, loadingTickets: false });
          }
        }
      );
    }
  };

  closeUserInfoDialog = val => {
    this.setState({ showInfoDialog: false });

    let self = this;

    if (val == null) return;

    const storeAnketa = () => userService.postWrapper(
      "api/anketa",
      val,
      function (data) {
        window.location.href = self.state.ticketRedirect;
      },
      function (error) {
        if (error != "") {
          self.setState({ error: error });
        }
      }
    );

    if (Object.hasOwn(val, "show_contacts")) {
      const showContacts = val.show_contacts.toString() == [-1].toString() ? 1 : 0;
      delete val.show_contacts;
      userService.postWrapper(
        "api/conference/" + self.state.id + "/update-student-settings",
        {show_contacts: showContacts},
        storeAnketa,
        function (error) {
          if (error != "") {
            self.setState({ error: error });
          }
        }
      );
    } else {
      storeAnketa();
    }
  };

  saveSpeakerOrder = () => {
    this.setState({ editSpeakerOrder: false });

    let self = this;

    const { speakers, id } = this.state;

    let post_data = speakers.map(function (item) {
      return {
        entity_type: "speaker",
        entity_id: item.id,
        order: item.order,
      };
    });

    userService.postWrapper(
      "api/conference/" + id + "/update-entity-settings",
      post_data,
      function (data) {
        console.log(data);
      },
      function (error) {
        if (error != "") {
          self.setState({ error: error });
        }
      }
    );
  };

  saveSponsorOrder = () => {
    this.setState({ editSponsorOrder: false });

    let self = this;

    const { sponsors, id } = this.state;

    let post_data = sponsors.map(function (item) {
      return {
        entity_type: "sponsor",
        entity_id: item.id,
        order: item.order,
      };
    });

    userService.postWrapper(
      "api/conference/" + id + "/update-entity-settings",
      post_data,
      function (data) {
        console.log(data);
      },
      function (error) {
        if (error != "") {
          self.setState({ error: error });
        }
      }
    );
  };

  openSectionInfo = (e, sectItem) => {
    e.stopPropagation();

    const { classes, dispatch } = this.props;

    const { id } = this.state;

    let self = this;

    if (sectItem.id < 1) return;

    self.setState({
      sectionData: { ...sectItem },
      sectionInfoOpen: true,
    });

    /*
    userService.getWrapper(
      "api/section/" + sectItem.id + "?conference_id=" + id,
      (data) => {
        if (data.data.description) {
          let desc = {};
          try {
            desc = JSON.parse(data.data.description);
          } catch (e) {
            console.log(e);
          }
          self.setState({
            sectionData: { ...sectItem, description: desc },
            sectionInfoOpen: true,
          });
        } else {
          self.setState({
            sectionData: { ...sectItem, description: {} },
            sectionInfoOpen: true,
          });
        }
      },
      (error) => {
        dispatch(userActions.set_error(error));
      }
    );
    */
  };

  clickContacts = () => {
    const { classes, dispatch } = this.props;
    const { contacts } = this.state;

    if (contacts) {
      //dispatch(userActions.set_error(contacts, 'info'));
      //window.open(`mailto:${contacts}`);
      this.setState({ contactInfoOpen: true });
    }
  };

  handleStop = (result) => {
    let { speakers } = this.state;

    if (!result.destination) return;

    let arr = [...speakers];

    //Changing the position of Array element
    let removedItem = arr.splice(result.source.index, 1)[0];
    arr.splice(result.destination.index, 0, removedItem);

    console.log(arr);
    let arrLen = arr.length;

    arr = arr.map(function (item, index) {
      return { ...item, order: arrLen - index };
    });

    console.log(arr);
    this.setState({ speakers: arr });

    //Updating the list
    //setDragDropList(arr);
  };

  handleStopSpons = (result) => {
    let { sponsors } = this.state;

    if (!result.destination) return;

    let arr = [...sponsors];

    //Changing the position of Array element
    let removedItem = arr.splice(result.source.index, 1)[0];
    arr.splice(result.destination.index, 0, removedItem);

    console.log(arr);
    let arrLen = arr.length;

    arr = arr.map(function (item, index) {
      return { ...item, order: arrLen - index };
    });

    console.log(arr);
    this.setState({ sponsors: arr });
  };

  makeBooking = (e, slotItem, val) => {
    e.stopPropagation();

    this.clickStar(e, slotItem, val);
  };

  openSlotInfo = (slotItem) => {
    let self = this;
    this.setState({ slotData: { ...slotItem }, slotInfoOpen: true });

    console.log(slotItem);

    /*
    if (slotItem.speakers.length == 0) return;

    let urls = slotItem.speakers.map((item) => "api/speaker/" + item.id);


    userService.getAsync(
      urls,
      (data) => {
        console.log("SPP", data);
        let speakers = data.map(item=>item.data);
        //self.setState({ slotData: { ...slotItem, speakers }, slotInfoOpen: true });


      },
      (error) => {
        if (error != "") {
          self.setState({ error, isLoading: false });
          self.props.dispatch(userActions.set_error(error));
        }
      }
    );

    onClick={(e) =>
                                            self.openSectionInfo(e, sectItem)
                                          }
    */
  };

  renderSlots = (slots, cols = 1) => {
    const self = this;

    const { isPublic, format, groupBySections, isMobile } = this.state;

    if (slots[0].id == 4) console.log(slots);

    return slots.map((slotItem, slotIndex) => (
      <li
        style={{ cursor: "pointer", height: isMobile || cols > 1 ? 210 : 130 }}
        className="item"
        key={slotIndex}
        onClick={(e) => {
          console.log(slotItem);
          e.stopPropagation();
          self.openSlotInfo(slotItem);
        }}
      >
        <div className="time">
          <div>{slotItem.time ? slotItem.time : "   "}</div>
          <div>{slotItem.endtime ? slotItem.endtime : "  ---  "}</div>

          {format == "hybrid" && slotItem.slot_type == "lesson" && (
            <div
              className="title-theme"
              style={{
                marginTop: 5,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              {slotItem.type === "online" && (
                <div title={i18next.t("online_type")}>
                  <img src={onlineLogo} alt="" />
                </div>
              )}
              {slotItem.type === "offline" && (
                <div title={i18next.t("offline_type")}>
                  <img src={offlineLogo} alt="" />
                </div>
              )}
              {slotItem.type === "hybrid" && (
                <div title={i18next.t("online_plus_offline_type")}>
                  <img src={onlineLogo} alt="" />
                </div>
              )}
              {slotItem.type === "hybrid" && (
                <div
                  title={i18next.t("online_plus_offline_type")}
                  style={{ marginLeft: 3 }}
                >
                  <img src={offlineLogo} alt="" />
                </div>
              )}
            </div>
          )}
        </div>
        <div className="theme">
          <div style={{ display: "flex", flexDirection: "row", marginTop: 5 }}>
            {
              <div>
                <img alt="" />
              </div>
            }

            <div>
              <div className="title-theme" style={{ fontSize: 16 }}>
                {replaceName(slotItem.name)}
              </div>
              <div
                className="desc-theme"
                style={{ fontSize: 16, marginTop: 10 }}
              >
                {slotItem.sp_str}
              </div>
              {groupBySections && slotItem.type != "online" && (
                <div
                  className="desc-theme"
                  style={{ marginTop: 10, fontSize: 16 }}
                >
                  {i18next.t("room")}:{" "}
                  {slotItem.room_number
                    ? slotItem.room_number
                    : i18next.t("to_be_specified")}
                </div>
              )}
            </div>
          </div>
          <div
            style={{
              marginTop: 10,
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              justifyContent: "space-between",
            }}
          >
            <div>
              {slotItem.tags &&
                slotItem.tags.map((item) => (
                  <span style={{ paddingRight: 10, color: "blue" }}>
                    #{item}
                  </span>
                ))}
            </div>
            {slotItem.available_seats != undefined && !slotItem.is_favorite && (
              <Button
                variant="outlined"
                onClick={(e) => self.makeBooking(e, slotItem, 1)}
                style={{ maxWidth: 300 }}
              >
                Записаться (осталось: {slotItem.available_seats})
              </Button>
            )}
            {slotItem.available_seats != undefined && slotItem.is_favorite && (
              <Button
                variant="outlined"
                onClick={(e) => self.makeBooking(e, slotItem, 0)}
                style={{ maxWidth: 300 }}
              >
                {i18next.t("cancel_partip")}
              </Button>
            )}
          </div>
        </div>
        {slotItem.available_seats == undefined &&
          !isPublic &&
          slotItem.is_favorite == false && (
            <div
              className="favorite"
              onClick={(e) => self.clickStar(e, slotItem, 1)}
            >
              <StarNotActive />
            </div>
          )}

        {slotItem.available_seats == undefined &&
          !isPublic &&
          slotItem.is_favorite == true && (
            <div
              className="favorite active"
              onClick={(e) => self.clickStar(e, slotItem, 0)}
            >
              <StarActive />
            </div>
          )}
      </li>
    ));
  };

  render() {
    const self = this;
    const {
      id,
      isLoading,
      image,
      comment,
      name,
      start_time,
      end_time,
      addr,
      format,
      navigation_building_description,
      navigation_description,
      map_description,
      map_image,
      map_link,
      navigation_building_image,
      navigation_image,
      hasTicket,
      sponsors,
      scheduleData,
      oneDaySchedule,
      curDateIndex,
      table_class,
      wifi,
      tickets,
      openTicket,
      slotData,
      sectionData,
      slotInfoOpen,
      showInfoDialog,
      sectionInfoOpen,
      needFields,
      optionalFields,
      showContactsSetting,
      favChedule,
      groupBySections,
      isPublic,
      isErr,
      contactInfoOpen,
      contacts,
      urlt,
      ticket_text,
      lessons,
      oneColumn,
      speakerData,
      speakerInfoOpen,
      sponsorData,
      sponsorInfoOpen,
      isNoMenu,
      speakers,
      isMobile,
      speakersOpen,
      openQR,
      qrData,
      editSpeakerOrder,
      editSponsorOrder,
      lang,
      questionable,
    } = this.state;

    return (
      <div className="layout" style={{ width: "100%", overflowX: "hidden" }}>
        {isNoMenu && (
          <div
            style={{ position: "absolute", top: 30, left: 20, fontSize: 20 }}
          >
            <div className="lang">
              <button className="lang__btn" onClick={this.changeLang}>
                {lang == "ru" && (
                  <img src={ruFlag} alt="" className="lang__flag" />
                )}
                {lang == "en" && (
                  <img src={enFlag} alt="" className="lang__flag" />
                )}
                {lang == "cn" && (
                  <img src={cnFlag} alt="" className="lang__flag" />
                )}
                <span style={{ fontSize: 16 }}>{lang}</span>
              </button>
            </div>
          </div>
        )}

        {(!id || isErr) && <ConferenceNotSelected page={"client_view"} />}
        {id > 0 && !isErr && (
          <div>
            {isNoMenu && (
              <button
                style={{ marginLeft: 100, marginTop: 20 }}
                className="btn-gradient"
                onClick={this.clickToList}
              >
                {i18next.t("confs_list")}
              </button>
            )}
            {isMobile && (
              <div
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                  justifyContent: "center",
                }}
              >
                <img
                  style={{ width: "95%", height: "auto" }}
                  src={image ? serverUrl + image : confLogo}
                  alt=""
                />
              </div>
            )}
            <section className="member-top">
              <div className="conf-detail conf-detail-member">
                {!isMobile && (
                  <div className="conf-img">
                    <img src={image ? serverUrl + image : confLogo} alt="" />
                  </div>
                )}
                <div className="conf-info">
                  <div className="title h1">{name}</div>
                  <div className="meta" style={{ marginBottom: 10 }}>
                    <div className="date">
                      <span>
                        {confService.getConfDateTimeStr(start_time, end_time)}
                      </span>
                    </div>
                  </div>
                  <div className="meta" style={{ marginBottom: 10 }}>
                    <div className="address">
                      <span>{addr}</span>
                    </div>
                  </div>
                  <div className="meta" style={{ marginBottom: 10 }}>
                    <div
                      className="date"
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <div
                        style={{
                          marginRight: 10,
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        {format === "online" && (
                          <div title={i18next.t("online_type")}>
                            <img src={onlineLogo} alt="" />
                          </div>
                        )}
                        {format === "offline" && (
                          <div title={i18next.t("offline_type")}>
                            <img src={offlineLogo} alt="" />
                          </div>
                        )}
                        {format === "hybrid" && (
                          <div title={i18next.t("online_plus_offline_type")}>
                            <img src={onlineLogo} alt="" />
                          </div>
                        )}
                        {format === "hybrid" && (
                          <div
                            title={i18next.t("online_plus_offline_type")}
                            style={{ marginLeft: 3 }}
                          >
                            <img src={offlineLogo} alt="" />
                          </div>
                        )}
                      </div>
                      <span>
                        {format === "online" && i18next.t("online_type")}
                        {format === "offline" && i18next.t("offline_type")}
                        {format === "hybrid" &&
                          i18next.t("online_plus_offline_type")}
                      </span>
                    </div>
                  </div>

                  {isMobile && !isPublic && hasTicket && wifi && (
                    <div className="meta" style={{ marginBottom: 10 }}>
                      <div className="date">
                        <span>
                          {i18next.t("inet_on_site")} {": "} {wifi}
                        </span>
                      </div>
                    </div>
                  )}

                  {urlt && urlt.length > 0 && format != "offline" && !hasTicket && (
                    <div className="meta" style={{ marginBottom: 10 }}>
                      <div className="date">
                        <span>{i18next.t("url_after_ticket_purchase")}</span>
                      </div>
                    </div>
                  )}

                  {urlt && urlt.length > 0 && format != "offline" && hasTicket  && (
                    <div className="btn-member" style={{ marginBottom: 10 }}>
                        <button className="btn-border" style={{marginLeft: 5}} onClick={() => {
                          if (this.state.translation) {
                            this.setState({translationFramesOpen: true, translationFramesTranslation: this.state.translation});
                          } else {
                            window.open(urlt);
                          }
                        }}>
                          {i18next.t("view_translation")}
                        </button>
                    </div>
                  )}

                  <div className="btn-member">
                    {tickets.length > 0 && <button
                      className="btn-gradient"
                      onClick={this.clickTicketMenu}
                    >
                      {hasTicket ? i18next.t("show_ticket") : ticket_text}
                    </button>}

                    <button
                      className="btn-border"
                      onClick={this.clickContacts}
                      style={{ marginLeft: 5 }}
                    >
                      <span>{i18next.t("connect_to_orgs")}</span>
                    </button>
                  </div>
                </div>
                {!isMobile && !isPublic && hasTicket && wifi && (
                  <div className="conf-links">
                    <a className="btn-icon" style={{ marginBottom: 15 }}>
                      <img src={inetLogo} style={{ marginRight: 10 }} />
                      <span>
                        {i18next.t("inet_on_site")} {": "} {wifi}
                      </span>
                    </a>
                  </div>
                )}
              </div>
            </section>

            {(self.props ||
              (hasTicket && (comment || sponsors.length > 0))) && (
              <section className="description block-default">
                {comment && (
                  <div className="title">{i18next.t("event_desc")}</div>
                )}
                {!comment && (
                  <p className="text_n" style={{ fontSize: 18 }}>
                    {i18next.t("no_desc")}
                  </p>
                )}
                {comment && (
                <div
                          style={{ fontSize: 14 }}
                          dangerouslySetInnerHTML={{ __html: comment }}
                        />)}

                {speakers.length > 0 && (
                  <div className="partners" style={{ marginBottom: 20 }}>
                    <div
                      className="title-small"
                      style={{ margin: 0 }}
                      onClick={() =>
                        self.setState({ speakersOpen: !speakersOpen })
                      }
                    >
                      <span
                        style={{ textDecoration: "underline" }}
                        div
                        className="title"
                      >
                        {i18next.t("speakers")}
                      </span>
                      {speakersOpen ? (
                        <ExpandLess fontSize="large" />
                      ) : (
                        <ExpandMore fontSize="large" />
                      )}
                    </div>
                    <Collapse
                      in={speakersOpen}
                      timeout="auto"
                      style={{ marginTop: 10 }}
                    >
                      {editSpeakerOrder && (
                        <div>
                          <Button
                            style={{ marginBottom: 10 }}
                            variant="contained"
                            onClick={this.saveSpeakerOrder}
                          >
                            {i18next.t("btn_save")}
                          </Button>
                        </div>
                      )}
                      {editSpeakerOrder && (
                        <div>
                          <Button
                            style={{ marginBottom: 10 }}
                            variant="contained"
                            onClick={() => {
                              this.setState({ editSpeakerOrder: false });
                              this.getSchedule(this.state.id);
                            }}
                          >
                            {i18next.t("btn_cancel")}
                          </Button>
                        </div>
                      )}

                      {editSpeakerOrder && (
                        <DragDropContext
                          onDragEnd={this.handleStop}
                          onDragStart={this.handleStart}
                        >
                          <Droppable droppableId="drag-drop-list">
                            {(provided, snapshot) => (
                              <div
                                className="drag-drop-list-container"
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                              >
                                {speakers
                                  .sort((a, b) => b.order - a.order)
                                  .map((item, index) => (
                                    <Draggable
                                      key={item.id}
                                      draggableId={item.name}
                                      index={index}
                                    >
                                      {(provided) => (
                                        <div
                                          className="item-card"
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            self.setState({
                                              speakerData: { ...item },
                                              speakerInfoOpen: true,
                                            })
                                          }
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                        >
                                          <img
                                            style={{
                                              width: 120,
                                              height: 120,
                                              borderRadius: 60,
                                              marginBottom: 10,
                                            }}
                                            src={
                                              item.photo
                                                ? serverUrl +
                                                  item.photo.replace(
                                                    "public",
                                                    "storage"
                                                  )
                                                : personLogo
                                            }
                                            alt=""
                                          />
                                          <span style={{ fontSize: 20 }}>
                                            {item.name}
                                          </span>
                                        </div>
                                      )}
                                    </Draggable>
                                  ))}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                      )}
                      {self.props.isOrg && !editSpeakerOrder && (
                        <Button
                          variant="contained"
                          onClick={() =>
                            this.setState({ editSpeakerOrder: true })
                          }
                        >
                          {i18next.t("change_order")}
                        </Button>
                      )}
                      {!editSpeakerOrder && (
                        <ul>
                          {speakers
                            .sort((a, b) => b.order - a.order)
                            .map((item) => (
                              <li
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  self.setState({
                                    speakerData: { ...item },
                                    speakerInfoOpen: true,
                                  })
                                }
                              >
                                <img
                                  style={{
                                    width: 120,
                                    height: 120,
                                    borderRadius: 60,
                                    marginBottom: 10,
                                  }}
                                  src={
                                    item.photo
                                      ? serverUrl +
                                        item.photo.replace("public", "storage")
                                      : personLogo
                                  }
                                  alt=""
                                />
                                <span style={{ fontSize: 20 }}>
                                  {item.name}
                                </span>
                              </li>
                            ))}
                        </ul>
                      )}
                    </Collapse>
                  </div>
                )}

                {sponsors.length > 0 && (
                  <div className="partners">
                    <div className="title" style={{ margin: 0 }}>
                      {i18next.t("partners_and_sponsors")}
                    </div>

                    {self.props.isOrg && !editSponsorOrder && (
                      <Button
                        variant="contained"
                        onClick={() =>
                          this.setState({ editSponsorOrder: true })
                        }
                      >
                        {i18next.t("change_order")}
                      </Button>
                    )}

                    <ul>
                      {!editSponsorOrder &&
                        sponsors.length > 0 &&
                        sponsors
                          .sort((a, b) => b.order - a.order)
                          .map((item) => (
                            <li
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                self.setState({
                                  sponsorData: { ...item },
                                  sponsorInfoOpen: true,
                                })
                              }
                            >
                              <img
                                style={{
                                  width: 120,
                                  height: 120,
                                  borderRadius: 60,
                                  marginBottom: 10,
                                }}
                                src={
                                  item.logo
                                    ? serverUrl +
                                      item.logo.replace("public", "storage")
                                    : personLogo
                                }
                                alt=""
                              />
                              <span style={{ fontSize: 20 }}>
                                {item.brandname}
                              </span>
                            </li>
                          ))}
                    </ul>

                    {editSponsorOrder && (
                      <div>
                        <Button
                          style={{ marginBottom: 10 }}
                          variant="contained"
                          onClick={this.saveSponsorOrder}
                        >
                          {i18next.t("btn_save")}
                        </Button>
                      </div>
                    )}
                    {editSponsorOrder && (
                      <div>
                        <Button
                          style={{ marginBottom: 10 }}
                          variant="contained"
                          onClick={() => {
                            this.setState({ editSponsorOrder: false });
                            this.getConfInfo(this.state.id);
                          }}
                        >
                          {i18next.t("btn_cancel")}
                        </Button>
                      </div>
                    )}

                    {editSponsorOrder && (
                      <DragDropContext
                        onDragEnd={this.handleStopSpons}
                        onDragStart={this.handleStart}
                      >
                        <Droppable droppableId="drag-drop-list">
                          {(provided, snapshot) => (
                            <div
                              className="drag-drop-list-container"
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {sponsors
                                .sort((a, b) => b.order - a.order)
                                .map((item, index) => (
                                  <Draggable
                                    key={item.id}
                                    draggableId={item.brandname}
                                    index={index}
                                  >
                                    {(provided) => (
                                      <div
                                        className="item-card"
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          self.setState({
                                            speakerData: { ...item },
                                            speakerInfoOpen: true,
                                          })
                                        }
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <img
                                          style={{
                                            width: 120,
                                            height: 120,
                                            borderRadius: 60,
                                            marginBottom: 10,
                                          }}
                                          src={
                                            item.logo
                                              ? serverUrl +
                                                item.logo.replace(
                                                  "public",
                                                  "storage"
                                                )
                                              : personLogo
                                          }
                                          alt=""
                                        />
                                        <span style={{ fontSize: 20 }}>
                                          {item.brandname}
                                        </span>
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    )}
                  </div>
                )}
              </section>
            )}

            {lessons.length > 0 && (
              <section className="block-default">
                <div className="title">{i18next.t("program")}</div>

                <button
                  style={{ marginBottom: 10 }}
                  className="btn-gradient"
                  onClick={this.downloadPdf}
                >
                  {i18next.t("download_pdf")}
                </button>

                {!isPublic && (
                  <button
                    style={{ marginBottom: 10, marginLeft: 10 }}
                    className="btn-gradient"
                    onClick={() => self.clickDate(-1)}
                  >
                    {i18next.t("favorites")}
                  </button>
                )}

                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div
                    className="tab-switcher"
                    style={{ marginBottom: isMobile ? 10 : 30 }}
                  >
                    <ul className="tabs-member">
                      {scheduleData.length > 0 &&
                        scheduleData.map((item, index) => (
                          <li
                            data-tab="27"
                            onClick={() =>
                              self.clickDate(
                                index,
                                groupBySections,
                                scheduleData
                              )
                            }
                            className={index == curDateIndex ? "active" : ""}
                          >
                            {item.title}
                          </li>
                        ))}
                    </ul>
                  </div>
                  {!isMobile && (
                    <FormGroup style={{ marginLeft: 20 }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="default"
                            checked={groupBySections}
                            onClick={this.clickGroup}
                          />
                        }
                        label={i18next.t("group_by_section")}
                      />
                    </FormGroup>
                  )}
                  {!isMobile && (
                    <FormGroup style={{ marginLeft: 20 }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="default"
                            checked={oneColumn}
                            onClick={() =>
                              this.setState({ oneColumn: !oneColumn })
                            }
                          />
                        }
                        label={i18next.t("in_one_col")}
                      />
                    </FormGroup>
                  )}
                </div>
                {isMobile && (
                  <FormGroup style={{ marginLeft: 20 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="default"
                          checked={groupBySections}
                          onClick={this.clickGroup}
                        />
                      }
                      label={i18next.t("group_by_section")}
                    />
                  </FormGroup>
                )}

                <div className="tabs-client-content">
                  <div
                    style={{ width: "100%" }}
                    className={oneColumn || isMobile ? "" : "row"}
                  >
                    {groupBySections == false &&
                      oneDaySchedule.rooms &&
                      oneDaySchedule.rooms.map((roomItem, roomInd) => (
                        <div
                          className={oneColumn ? "md-12" : table_class}
                          style={{
                            paddingTop: oneColumn || isMobile ? 20 : 0,
                            borderTop:
                              (oneColumn || isMobile) && roomInd > 0
                                ? "solid 1px"
                                : "",
                          }}
                        >
                          <div className="title-small">
                            <span style={{ fontWeight: 600 }}>
                              {oneColumn || isMobile
                                ? i18next.t("room") + ": "
                                : ""}
                            </span>
                            {roomItem.room_number
                              ? roomItem.room_number
                              : i18next.t("to_be_specified")}
                          </div>
                          <ul className="list-schedule">
                            {roomItem.sections &&
                              roomItem.sections.map((sectItem, sectIndex) => (
                                <li>
                                  <ul>
                                    {sectItem.name != NO_SECTION && (
                                      <li
                                        className="item"
                                        style={{
                                          backgroundColor: "#ffffdd",
                                          cursor: "pointer",
                                        }}
                                        onClick={(e) =>
                                          self.changeSectOpen(
                                            e,
                                            roomInd,
                                            sectIndex,
                                            false
                                          )
                                        }
                                      >
                                        <div
                                          className="time"
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                          }}
                                        >
                                          <div style={{ marginRight: 5 }}>
                                            {i18next.t("section")}
                                          </div>
                                          <div
                                            style={{
                                              borderRadius: 20,
                                              backgroundColor: "#eeeeee",
                                            }}
                                          >
                                           
                                          </div>
                                        </div>
                                        <div className="theme">
                                          <div
                                            className="title-theme"
                                            style={{ fontSize: 16 }}
                                          >
                                            {sectItem.description.time
                                              ? "(" +
                                                sectItem.description.time +
                                                ") "
                                              : ""}
                                            {sectItem.name}
                                          </div>
                                        </div>
                                      </li>
                                    )}
                                    {self.renderSlots(
                                      sectItem.slots,
                                      oneColumn
                                        ? 1
                                        : oneDaySchedule.rooms.length
                                    )}
                                  </ul>
                                </li>
                              ))}
                          </ul>
                        </div>
                      ))}

                    {groupBySections &&
                      oneDaySchedule.sections &&
                      oneDaySchedule.sections.map((sItem, sInd) => (
                        <div
                          className={
                            oneColumn || isMobile ? "md-12" : table_class
                          }
                          style={{
                            cursor: "pointer",
                            paddingTop: oneColumn || isMobile ? 20 : 0,
                            borderTop:
                              (oneColumn || isMobile) && sInd > 0
                                ? "solid 1px"
                                : "",
                          }}
                          onClick={(e) =>
                            self.changeSectOpen(e, -1, sInd, true)
                          }
                        >
                          {(oneDaySchedule.sections.length > 1 ||
                            sItem.name) && (
                            <div
                              className="title-small"
                              style={{
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <div style={{ fontWeight: 600 }}>
                                {oneColumn || isMobile
                                  ? i18next.t("section") + ": "
                                  : ""}
                              </div>
                              <div>
                                {sItem.isOpen ? (
                                  <ExpandLess
                                    fontSize="large"
                                    style={{ marginTop: -5 }}
                                  />
                                ) : (
                                  <ExpandMore
                                    fontSize="large"
                                    style={{ marginTop: -5 }}
                                  />
                                )}
                              </div>
                              <div>
                                {sItem.description &&
                                (sItem.description.time ||
                                  sItem.description.room)
                                  ? "(" +
                                    sItem.description.time +
                                    (sItem.description.time &&
                                    sItem.description.room
                                      ? ", "
                                      : "") +
                                    sItem.description.room +
                                    ") "
                                  : ""}

                                {sItem.name
                                  ? replaceName(sItem.name)
                                  : i18next.t("to_be_specified")}
                              </div>
                            </div>
                          )}
                           <Collapse in={sItem.isOpen} timeout="auto">
                          <ul className="list-schedule">
                            {sItem.slots &&
                              sItem.slots.map((sectItem, sectIndex) => (
                                <li style={{ marginBottom: 10 }}>
                                  {self.renderSlots(
                                    [sectItem],
                                    oneColumn
                                      ? 1
                                      : oneDaySchedule.sections.length
                                  )}
                                </li>
                              ))}
                          </ul>
                          </Collapse>
                        </div>
                      ))}

                    {curDateIndex < 0 && favChedule.length == 0 && (
                      <ul>
                        <li>
                          <div>{i18next.t("fav_empty")}</div>
                        </li>
                      </ul>
                    )}
                    {curDateIndex < 0 && favChedule.length > 0 && (
                      <div className="row" style={{ width: "100%" }}>
                        <div className="col-md-12">
                          <ul className="list-schedule">
                            {favChedule.map((item) => (
                              <li
                                className="item"
                                onClick={() =>
                                  self.setState({
                                    slotData: { ...item },
                                    slotInfoOpen: true,
                                  })
                                }
                                style={{ cursor: "pointer", height: 100 }}
                              >
                                <div className="time" style={{ width: 130 }}>
                                  <div>{item.ftime}</div>
                                  <div style={{ paddingTop: 5 }}>
                                    {item.fdate}
                                  </div>
                                </div>

                                <div className="theme">
                                  <div
                                    className="title-theme"
                                    style={{ fontSize: 16 }}
                                  >
                                    {replaceName(item.name)},{" "}
                                    {item.room_number
                                      ? item.room_number
                                      : i18next.t("to_be_specified")}{" "}
                                  </div>
                                  <div className="desc-theme">
                                    {item.sp_str}
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </section>
            )}

            {format != "online" && self.isNavigateEnabled() && (
              <section className="block-default navigation-desc">
                <div className="title">{i18next.t("navigation")}</div>

                {(navigation_building_description ||
                  navigation_building_image) && (
                  <div className="row" style={{ width: "100%" }}>
                    <div className="col-md-6">
                      <img
                        src={
                          navigation_building_image
                            ? serverUrl + navigation_building_image
                            : ""
                        }
                        style={{ maxHeight: 500 }}
                        alt=""
                      />
                    </div>
                    <div className="col-md-6">
                      <div className="title-small">{i18next.t("nav_bld")}</div>
                      <p className="text_n">
                        {navigation_building_description}
                      </p>
                    </div>
                  </div>
                )}

                {(navigation_description || navigation_image) && (
                  <div className="row" style={{ width: "100%" }}>
                    <div className="col-md-6">
                      <img
                        src={
                          navigation_image ? serverUrl + navigation_image : ""
                        }
                        style={{ maxHeight: 500 }}
                        alt=""
                      />
                    </div>
                    <div className="col-md-6">
                      <div className="title-small">{i18next.t("nav_bld")}</div>
                      <p className="text_n">{navigation_description}</p>
                    </div>
                  </div>
                )}
                {!isMobile && (map_description || map_link) && (
                  <div className="row" style={{ width: "100%" }}>
                    <div className="col-md-6">
                      <div className="title-small">{i18next.t("nav_map")}</div>
                      <p className="text_n">
                        {" "}
                        <div
                          style={{ fontSize: 14 }}
                          dangerouslySetInnerHTML={{ __html: map_description }}
                        />
                      </p>
                    </div>
                    <div className="col-md-6">
                      <div
                        style={{
                          height: window.innerWidth * 0.4,
                          outline: "1px solid #888",
                        }}
                      >
                        {map_link && (
                          <iframe
                            src={map_link}
                            width="100%"
                            height="100%"
                            frameborder="1"
                          ></iframe>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {isMobile && map_description && (
                  <div style={{ width: "100%" }}>
                    <div className="title-small">{i18next.t("nav_map")}</div>
                    <p className="text_n">
                      {" "}
                      <div
                        style={{ fontSize: 14 }}
                        dangerouslySetInnerHTML={{ __html: map_description }}
                      />
                    </p>
                  </div>
                )}
                {isMobile && map_link && (
                  <div style={{ width: "100%" }}>
                    <div style={{ outline: "1px solid #888" }}>
                      {map_link && (
                        <iframe
                          src={map_link}
                          width="100%"
                          height={window.screen.width * 0.9}
                          frameborder="1"
                        ></iframe>
                      )}
                    </div>
                  </div>
                )}
              </section>
            )}
          </div>
        )}
        <TicketDialog
          handleClose={self.closeTicketDialog}
          tickets={tickets}
          dialogOpen={openTicket}
        />
        <QRDialog
          handleClose={() => self.setState({ openQR: false, qrData: null })}
          qrData={qrData}
          dialogOpen={openQR}
        />
        <UserInfoDialog
          handleClose={self.closeUserInfoDialog}
          flds={needFields}
          optionalFields={optionalFields}
          showContactsSetting={showContactsSetting}
          dialogOpen={showInfoDialog}
          fullMode={false}
        />

        <SlotInfo
          texts={{
            to_be_specified: i18next.t("to_be_specified"),
            room: i18next.t("room"),
            theme: i18next.t("thema"),
            ask_q: i18next.t("ask_question_from_speaker"),
            format: i18next.t("format"),
            time: i18next.t("time"),
            speakers: i18next.t("speakers"),
            desc: i18next.t("dialog_desc"),
            urltrans: i18next.t("url_translation"),
            qtext: i18next.t("enter_quest_text"),
            send: i18next.t("send_question_to_speaker"),
            cancel: i18next.t("btn_cancel"),
            text: i18next.t("text"),
            viewtrans: i18next.t("view_translation"),
          }}
          handleClose={() => self.setState({ slotInfoOpen: false })}
          data={slotData}
          open={slotInfoOpen}
          sendQ={self.sendQ}
          hasTicket={hasTicket}
          isMobile={isMobile}
          openSpeaker={self.openSpeakerFromSlot}
          isHybird={format == "hybrid"}
          questionable={questionable}
          isOrg={self.props.isOrg}
        />
        <SectionInfo
          texts={{
            to_be_specified: i18next.t("to_be_specified"),
            room: i18next.t("room"),
            theme: i18next.t("name"),
            time: i18next.t("time"),
            speakers: i18next.t("speakers"),
            desc: i18next.t("dialog_desc"),
            urltrans: i18next.t("url_translation"),
            cancel: i18next.t("btn_cancel"),
            text: i18next.t("text"),
            viewtrans: i18next.t("view_translation"),
          }}
          handleClose={() => self.setState({ sectionInfoOpen: false })}
          data={sectionData}
          open={sectionInfoOpen}
          isPublic={isPublic ? true : hasTicket ? false : true}
          redirectToConf={self.redirectToConf}
          isMobile={isMobile}
        />
        <SpeakerInfo
          handleClose={() => self.setState({ speakerInfoOpen: false })}
          data={speakerData}
          open={speakerInfoOpen}
          texts={{
            no_text: i18next.t("to_be_specified"),
            city: i18next.t("dialog_city"),
          }}
        />
        <SponsorInfo
          handleClose={() => self.setState({ sponsorInfoOpen: false })}
          data={sponsorData}
          open={sponsorInfoOpen}
          texts={{
            no_text: i18next.t("to_be_specified"),
            title: i18next.t("name"),
            desc: i18next.t("dialog_desc"),
          }}
        />
        <ContactInfo
          handleClose={() => self.setState({ contactInfoOpen: false })}
          data={contacts}
          open={contactInfoOpen}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { app, user, authentication, schedule } = state;

  return {
    user,
    app,
    authentication,
    schedule,
  };
}

const connectedClientConfPage = connect(mapStateToProps)(ClientConfPage);
export { connectedClientConfPage as ClientConfPage };
